















































import Button from '@/app/ui/components/Button/index.vue'
import DateTimePickerV2, {
  IDateRangeValue,
} from '@/app/ui/components/DateTimePickerV2/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import CloseLine from '@/app/ui/assets/close_line.vue'
import PickupController from '@/app/ui/controllers/PickupController'
import { Utils } from '@/app/infrastructures/misc'
import dayjs from 'dayjs'

@Component({
  components: {
    Modal,
    DateTimePickerV2,
    Button,
    CloseLine,
  },
})
export default class ModalExportCorporate extends Vue {
  @Prop({ default: false }) visible!: boolean

  controller = PickupController

  dateRange = <IDateRangeValue>{
    start: null,
    end: null,
  }

  public get decideDisableExportCorporateButton(): boolean {
    return (
      this.controller.isLoadingExportCorporate ||
      (!this.dateRange?.start || !this.dateRange?.end)
    )
  }

  public exportCorporate(): void {
    const payload = {
      file_name: `CORPORATE-${Utils.formatDateWithIDLocale(
        dayjs(),
        'YYYYMMDDHHmss'
      )}`,
      dateFrom: Utils.formatDateWithIDLocale(
        <string>this.dateRange?.start?.toISOString(),
        'YYYY-MM-DD'
      ),
      dateTo: Utils.formatDateWithIDLocale(
        <string>this.dateRange?.end?.toISOString(),
        'YYYY-MM-DD'
      ),
      shipmentTypeGroup: 'CORPORATE',
    }

    this.controller.exportCorporate(payload)
  }

  public resetValue(): void {
    this.dateRange = {
      end: null,
      start: null,
    }
  }

  @Watch('controller.isSuccessExportCorporate')
  onSuccessExportCorporate(data: boolean) {
    if (data) {
      this.resetValue()
      this.$emit('cancel')
    }
  }
}
